import React from 'react';
import isEmpty from 'lodash/isEmpty';
import CarIcon from '../../../public/static/theme-capcar/car-light.svg';
import type {Hit} from '../../../types/Algolia';
import ProductCard from '../ProductCard/AlgoliaCard';
import Link, {LinkType} from '../../system/Link';

type Props = {
	lastProducts: Hit[];
	title: React.ReactNode;
	btnText: string;
	as?: string;
	className?: string;
	classNameCard?: string;
};

const LastProducts = ({
	lastProducts,
	title,
	btnText,
	as,
	className = 'product-list max-w-6xl m-auto px-3',
	classNameCard = 'product-item',
}: Props) => {
	if (isEmpty(lastProducts)) return null;
	return (
		<>
			{title}

			<div className={className}>
				{lastProducts.map(product => (
					<div className={classNameCard} key={product.reference}>
						<ProductCard
							hit={product}
							tracking={{
								category: 'new_product_card',
							}}
						/>
					</div>
				))}
			</div>

			<Link
				href="/voiture-occasion"
				as={as}
				type={LinkType.IN_APP}
				className="flex justify-center my-10"
			>
				<a className="btn btn-green btn-link">
					<span className="flex items-center">
						<CarIcon className="mr-2" />
						{btnText}
					</span>
				</a>
			</Link>
		</>
	);
};

export {LastProducts};
