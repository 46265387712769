import React, {useContext} from 'react';
import Head from 'next/head';
import {BACKGROUND_URL, SITE_NAME} from '../../../constants';
import config from '../../../config';
import {I18nContext} from '../../../i18n';

const HomeMetadata = () => {
	const {translate} = useContext(I18nContext);
	return (
		<Head>
			<title>{translate('meta.home.title')}</title>
			<meta
				property="og:image"
				content={config.CDN_HOST + BACKGROUND_URL}
			/>
			<meta property="og:url" content={config.SITE_DOMAIN_URL} />
			<meta property="og:site_name" content={SITE_NAME} />
			<meta property="og:title" content={translate('meta.home.title')} />
			<meta
				property="og:description"
				content={translate('meta.home.description')}
			/>
			<meta property="og:type" content="article" />
			<meta
				name="description"
				content={translate('meta.home.description')}
			/>
			<meta name="title" content={translate('meta.home.title')} />
			<meta name="robots" content="index, follow" />
			<link rel="canonical" href={config.SITE_DOMAIN_URL} />
		</Head>
	);
};

export default HomeMetadata;
