import React from 'react';
import Carousel from './Carousel';
import {getReviews} from './review';

type Props = {
	reviewsData: any[];
};

const CustomerReview = ({reviewsData}: Props) => {
	return (
		<div className="bg-green-550 relative overflow-hidden py-10">
			<div className="flex flex-col text-center tablet:text-left">
				<div className="text-3xl px-4 pb-12 lg:pb-18 text-white text-center mx-auto">
					<span>Ne nous croyez pas sur parole, ️</span>
					<span className="font-bold block tablet:inline-block">
						voyez ce qu’ils ont à dire ❤️
					</span>
				</div>

				<Carousel elements={getReviews(reviewsData)} />
			</div>
		</div>
	);
};

export default CustomerReview;
