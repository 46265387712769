import React, {useContext} from 'react';
import {useAsync} from 'react-use';
import config from '../../../config';
import {AVIS_VERIFIES_LINK} from '../../../constants';
import {I18nContext} from '../../../i18n';
import {fetchDataCustomerReview} from '../../../utils/customerReview';
import {AnimateIcon, Stars} from '../../system';
import {LinkType} from '../../system/Link';

const {CDN_HOST} = config;

const AvisVerifieHover = () => {
	const {translate} = useContext(I18nContext);
	const {value: customerReview} = useAsync(
		() => fetchDataCustomerReview(),
		[],
	);

	if (!customerReview?.note) {
		return null;
	}

	return (
		<>
			<span>
				<Stars
					score={customerReview?.note}
					fill="fill-orange-250"
					opaqueEmptyIcon
				/>
			</span>
			<span className="text-green-410 text-lg font-bold ml-2 whitespace-nowrap">
				{translate('outOf', {
					numerator: customerReview?.note || 0,
					denominator: 5,
				})}
			</span>
			<AnimateIcon
				defaultIconSrc={`${CDN_HOST}/static/theme-capcar/logo-avis-verifies-green.svg`}
				hoverIconSrc={`${CDN_HOST}/static/theme-capcar/logo-avis-verifies.svg`}
				href={`${AVIS_VERIFIES_LINK}/avis-clients/capcar.fr`}
				type={LinkType.EXTERNAL}
				className="ml-3_4"
				iconClassName="h-full"
				target="_blank"
				rel="noopener noreferrer nofollow"
				alt="avis verifié"
				width="93.96"
				height="28"
			/>
		</>
	);
};

export default AvisVerifieHover;
