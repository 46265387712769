import React, {memo} from 'react';
import CustomerReview from './CustomerReview';

import reviews from './reviews.json';

const HomeReviews = () => {
	return <CustomerReview reviewsData={reviews} />;
};

export default memo(HomeReviews);
