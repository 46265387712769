import React, {useContext} from 'react';
import config from '../../../config';
import {I18nContext} from '../../../i18n';
import Link, {LinkType} from '../../system/Link';

const {CDN_HOST} = config;

const Category = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="max-w-6xl m-auto">
			<h2 className="text-2xl text-darkBlue-980 text-center my-10 mx-6 lg:mt-16 ">
				<span className="font-bold">{translate('ourCategories')} </span>
				<span className="block lg:inline">
					{translate('mostSeekWithIcon')}{' '}
				</span>
			</h2>
			<ul className="justify-around flex flex-wrap">
				{CATEGORIES_DISPLAYED.map(
					({label, query, pic, width, height}) => (
						<li
							key={label}
							className="w-36 mx-3 lg:mx-0 mb-4 transition duration-300 transform hover:scale-110 scale-100 shadow-cardXs rounded-xl bg-white touch-manipulation"
						>
							<Link
								href="/voiture-occasion"
								as={`/voiture-occasion?category=${query}`}
								type={LinkType.IN_APP}
							>
								<a className="text-blue-950 block flex flex-col h-full py-4 text-center">
									<img
										src={CDN_HOST + pic}
										className="m-auto"
										alt={`Illustration: ${translate(label)}`}
										width={width}
										height={height}
										loading="lazy"
									/>
									{translate(label)}
								</a>
							</Link>
						</li>
					),
				)}
			</ul>
		</div>
	);
};

const CATEGORIES_DISPLAYED = [
	{
		label: 'city-dweller',
		query: 'Citadine',
		pic: '/static/theme-capcar/category-citadine.svg',
		width: 69.24,
		height: 33.52,
	},
	{
		label: '4x4AndSub',
		pic: '/static/theme-capcar/category-4x4.svg',
		query: '4x4, SUV, Crossover',
		width: 79.38,
		height: 39.84,
	},
	{
		label: 'compact-sedan',
		pic: '/static/theme-capcar/category-berline-compacte.svg',
		query: 'Berline compacte',
		width: 91.4,
		height: 31.27,
	},
	{
		label: 'sedan',
		pic: '/static/theme-capcar/category-berline.svg',
		query: 'Berline',
		width: 95.4,
		height: 31.27,
	},
	{
		label: 'monospace',
		pic: '/static/theme-capcar/category-monospace.svg',
		query: 'Monospace',
		width: 85.95,
		height: 37.05,
	},
	{
		label: 'break',
		pic: '/static/theme-capcar/category-break.svg',
		query: 'Break',
		width: 95.41,
		height: 32.1,
	},
	{
		label: 'utility',
		pic: '/static/theme-capcar/category-utilitaire.svg',
		query: 'Utilitaire',
		width: 79.64,
		height: 41.55,
	},
];

export default Category;
