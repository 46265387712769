import React, {useContext} from 'react';
import {I18nContext} from '../../../i18n';
import {extendedPressLinksConfig} from '../../../constants';
import {ImageLazy} from '../../system/Image';
import Link, {LinkType} from '../../system/Link';

const TalkAboutUs = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="w-full bg-white py-10 mb-0 mb:mb-5 border-opacity-10 md:rounded-xl md:shadow-3xl">
			<div className="flex flex-col items-center">
				<h2 className="text-3xl px-8 text-center">
					{translate('talkAboutUs.here')}{' '}
					<span className="text-blue-275 font-bold">
						{translate('talkAboutUs.good')} {translate('icon.yeah')}
					</span>
				</h2>

				<ul className="mt-10 flex justify-center flex-wrap">
					{extendedPressLinksConfig.map(
						({hoverSrc, alt, href, width, height}) => (
							<li
								key={alt}
								className="flex justify-center mx-2 tablet:mx-10 my-6 h-10 w-33"
							>
								<Link type={LinkType.IN_APP} href={href}>
									<a className="w-full h-full">
										<ImageLazy
											src={hoverSrc}
											alt={alt}
											className="w-full h-full"
											width={width}
											height={height}
										/>
									</a>
								</Link>
							</li>
						),
					)}
				</ul>
			</div>
		</div>
	);
};

export default TalkAboutUs;
