import React, {useContext} from 'react';
import config from '../../../config';
import {I18nContext} from '../../../i18n';
import Link, {LinkType} from '../../system/Link';
import ImageLazy from '../../system/Image/ImageLazy';

const {CDN_HOST} = config;

const SellYourCarPlease = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="bg-white w-full pb-20 px-4">
			<div className="max-w-6xl m-auto bg-lightBlue-350 rounded-2xl flex flex-col lg:flex-row justify-around px-8 py-16 lg:px-16 items-center flex-wrap">
				<div className="pb-10 lg:pb-0">
					<p className="text-3xl pb-4">
						{translate('youWish')}{' '}
						<span className="font-bold block lg:inline">
							{translate('sellYourCarBis')}{' '}
						</span>
						<span className="block">{translate('weAreHere')}</span>
					</p>
					<Link href="/vendre-sa-voiture" type={LinkType.IN_APP}>
						<a className="btn btn-green btn-link px-2 py-2 block">
							{translate('iSellMyCar')}
						</a>
					</Link>
				</div>
				<ImageLazy
					className="m-auto"
					src={`${CDN_HOST}/static/theme-capcar/ilustration-vendeur.svg`}
					alt="vendeur"
					width="341.79"
					height="154.16"
				/>
			</div>
		</div>
	);
};

export default SellYourCarPlease;
