import React, {useContext} from 'react';
import Image from 'next/image';
import cx from 'classnames';
import config from '../../../config';
import {I18nContext} from '../../../i18n';

type Props = {
	name: string | null | undefined;
	review: string | null | undefined;
	img: string | null | undefined;
	className?: string;
};

const {CDN_HOST} = config;

const ReviewBlock = ({name, review, img, className}: Props) => {
	const {translate} = useContext(I18nContext);

	if (img) {
		return (
			<Image
				src={`${CDN_HOST}${img}`}
				className={cx('w-full shadow-card rounded-lg', className)}
				alt="Avis Client CapCar"
				loading="lazy"
				width="408"
				height="306"
				quality={70}
			/>
		);
	}

	if (name && review) {
		return (
			<div className={cx('bg-white p-5 rounded-lg w-full', className)}>
				<div className="font-bold">{translate(name)}</div>
				{translate(review)}
			</div>
		);
	}

	return null;
};

export const getReviews = (reviewsData: any[]) => {
	return reviewsData.reduce<React.ReactNode[][]>((reviews, col) => {
		reviews.push([
			<ReviewBlock
				key={col[0].name || col[0].img}
				name={col[0].name}
				review={col[0].review}
				img={col[0].img}
				className="mb-5"
			/>,
			<ReviewBlock
				key={col[1].name || col[1].img}
				name={col[1].name}
				review={col[1].review}
				img={col[1].img}
			/>,
		]);
		return reviews;
	}, []);
};
