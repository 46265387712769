import React, {useMemo} from 'react';
import ProductCard from '.';
import type {TrackingType} from '../../../types/TrackingType';
import type {Hit} from '../../../types/Algolia';

type Props = {
	hit: Hit;
	tracking?: TrackingType;
	nofollow?: boolean;
};

const AlgoliaProductCard = ({
	hit: {
		reference,
		state,
		critAir,
		brand,
		version,
		carPackage,
		motorization,
		year,
		mileage,
		energyDisplay,
		gearbox,
		price,
		financing,
		imageId,
		lastPriceDecreaseDate,
		hasBonus,
		isGoodDeal,
		isVeryGoodDeal,
		isFirstHand,
		warranty,
	},
	tracking,
	nofollow = false,
}: Props) => {
	const product = useMemo(
		() => ({
			id: reference,
			state,
			critAir,
			brand,
			version,
			carPackage,
			motorization,
			year,
			mileage,
			energyDisplay,
			gearbox,
			price,
			financing,
			imageId,
			lastPriceDecreaseDate,
			hasBonus,
			isGoodDeal,
			isVeryGoodDeal,
			isFirstHand,
			warranty,
		}),
		[reference, state],
	);
	return (
		<ProductCard
			product={product}
			tracking={tracking}
			nofollow={nofollow}
		/>
	);
};

export default AlgoliaProductCard;
