import React, {useContext, Suspense} from 'react';
import {
	Arguments,
	Category,
	Hero,
	Phone,
	TalkAboutUs,
	Meta,
} from '../components/pages/home';
import SellYourCarPlease from '../components/pages/home/SellYourCarPlease';
import AvisVerifieHover from '../components/rich/AvisVerifie/AvisVerifieHover';
import PressLinks from '../components/rich/PressLinks';
import Layout from '../components/rich/Layout';
import CustomerReview from '../components/rich/customer-reviews/HomeReview';
import {LastProducts} from '../components/rich/LastProducts';
import {I18nContext} from '../i18n';
import type {Hit} from '../types/Algolia';
import '../types/Algolia';
import {getLastProducts} from '../utils/algolia-repository';
import {withApollo} from '../contexts/apollo';
import {HeaderType} from '../components/rich/Header/types';

type PropsType = {
	lastProducts: Hit[];
};

const IndexPage = ({lastProducts}: PropsType) => {
	const {translate} = useContext(I18nContext);

	return (
		<Layout head={<Meta />} header={HeaderType.WITHOUT_PHONE}>
			<Hero />
			<div className="bg-white pb-10 pt-12 lg:pt-16">
				<div className="flex flex-col flex-wrap items-center justify-center max-w-6xl m-auto lg:h-24 lg:flex-row lg:justify-between px-7 lg:px-0 ">
					<div className="flex flex-wrap justify-center pb-10 lg:pb-0">
						<PressLinks />
					</div>
					<div className="flex">
						<Suspense>
							<AvisVerifieHover />
						</Suspense>
					</div>
				</div>
			</div>

			<div className="p-1 pb-16 bg-lightBlue-350">
				<Category />
				<hr className="hidden h-2 m-auto bg-white rounded-lg w-36 pt-0_6 lg:my-10 lg:block" />
				<div className="m-auto max-w-7xl">
					<LastProducts
						lastProducts={lastProducts}
						title={
							<h2 className="my-10 text-2xl text-center text-darkBlue-980">
								{translate('ourLast')}{' '}
								<span className="font-bold">
									{translate('lastCars')}
								</span>
							</h2>
						}
						btnText={translate('SeeAllProducts')}
						className="flex flex-wrap justify-around px-4 desktop:justify-between"
						classNameCard="lg:max-w-1/4 md:max-w-1/2 w-full p-2"
					/>
				</div>
			</div>

			<Suspense>
				<Arguments />
				<SellYourCarPlease />
				<Phone />
				<CustomerReview />
				<div className="w-full bg-white pb-3 tablet:pb-18">
					<div className="z-20 mx-auto max-w-6xl transform md:-translate-y-10">
						<TalkAboutUs />
					</div>
				</div>
			</Suspense>
		</Layout>
	);
};

export const getServerSideProps = async context => {
	context.res.setHeader('Cache-Control', 'public, max-age=3600');

	const [lastProducts] = await Promise.all([getLastProducts(4)]);
	return {
		props: {
			lastProducts,
		},
	};
};

export default withApollo(IndexPage);
