import React, {useContext} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import ArgumentCard from '../../system/Card/ArgumentCard';

const tileNumbersWithLinks = [
	[
		6,
		{
			href: '/comment-ca-marche/la-certification-dinspection',
			label: 'inspection',
		},
	],
	[
		5,
		{
			href: '/meilleur-prix-garanti',
			label: 'qualité / prix',
		},
	],
	[
		7,
		{
			href: '/comment-ca-marche/nos-garanties',
			label: 'garantie',
		},
	],
] as const;

const tiles = tileNumbersWithLinks.map(([index, {href, label}]) => ({
	icon: `ourOffer.${index}.icon`,
	title: `ourOffer.${index}.title`,
	description: `ourOffer.${index}.description`,
	key: index,
	link: {
		href,
		tracking: {
			category: 'Homepage',
			action: 'En savoir plus',
			label,
		},
	},
}));

const Arguments = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="w-full bg-white pt-10 pb-10">
			<div className="flex flex-col items-center bg-white">
				<h2 className="text-3xl px-8 text-center">
					{translate('welcomeAt')}{' '}
					<span className="text-blue-275 font-bold">
						{translate('websiteBrand')} {translate('icon.wave')}
					</span>
				</h2>
				<p className="text-center px-8 pb-10">
					{translate('buyRelaxed')}
				</p>

				<div
					className={cx(
						'flex justify-around lg:justify-between flex-wrap max-w-6xl w-full',
						{
							'max-w-4xl': tiles.length <= 3,
							'max-w-6xl': tiles.length > 3,
						},
					)}
				>
					{tiles.map(ArgumentCard)}
				</div>
			</div>
		</div>
	);
};

export default Arguments;
