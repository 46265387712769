import React, {useContext} from 'react';
import {I18nContext} from '../../../i18n';

const ExpertPhone = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="w-full bg-darkBlue-950 py-5">
			<div className="max-w-6xl mx-auto">
				<div className="max-w-5xl mx-5 tablet:mr-auto">
					<div className="text-3xl text-white text-left">
						<div className="text-5xl mb-4">
							{translate('icon.handshake')}{' '}
							{translate('icon.inspector')}{' '}
							{translate('icon.trophy')}
						</div>
						<div>{translate('step.title.1')}</div>
						<div className="block font-bold mt-3">
							{translate('step.title.2bis')}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExpertPhone;
