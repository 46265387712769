import React from 'react';
import {pressLinksConfig} from '../../../constants';
import {AnimateIcon} from '../../system';
import {LinkType} from '../../system/Link';

const PressLinks = () => (
	<>
		{pressLinksConfig.map(({src, hoverSrc, alt, href, height, width}) => (
			<AnimateIcon
				defaultIconSrc={src}
				hoverIconSrc={hoverSrc}
				iconClassName="h-6"
				className="mx-4 mb-4 lg:mb-0 first:ml-0 last:mr-0"
				href={href}
				type={LinkType.EXTERNAL}
				target="_blank"
				rel="noopener noreferrer nofollow"
				key={alt}
				alt={alt}
				height={height}
				width={width}
			/>
		))}
	</>
);

export default PressLinks;
