import React from 'react';

type Props = {
	elements: React.ReactNode[];
};

const Carousel = ({elements}: Props) => (
	<div className="customer-review-slider z-10">
		<div className="customer-review-slide-track">
			{elements.map((col, index) => (
				<div key={`col-${index + 1}`} className="customer-review-slide">
					{col}
				</div>
			))}
			{elements.map((col, index) => (
				<div key={`col-${index + 1}`} className="customer-review-slide">
					{col}
				</div>
			))}
		</div>
	</div>
);

export default Carousel;
