import * as React from 'react';
import {useContext} from 'react';
import {I18nContext} from '../../../i18n';
import Block from '../Block';
import Link, {LinkType} from '../Link';
import type {TrackingType} from '../../../types/TrackingType';

type Props = {
	icon: string;
	title: string;
	description: string;
	key: number;
	link?: {
		href: string;
		tracking: TrackingType;
	};
};

const ArgumentCard = ({icon, title, description, key, link}: Props) => {
	const {translate} = useContext(I18nContext);
	return (
		<Block
			className="rounded-xl p-6 my-3 flex-item mx-4 w-full tablet:mx-0 tablet:max-w-68 menu-shadow"
			key={key}
		>
			<p className="text-4_75xl">{translate(icon)}</p>
			<h3 className="text-blue-275 text-xl font-bold py-2 lg:py-4">
				{translate(title)}
			</h3>
			<p className="pb-3 tablet:h-28">{translate(description)}</p>
			{link && (
				<Link
					href={link.href}
					tracking={link.tracking}
					type={LinkType.IN_APP}
					className="mb-3 underline"
				>
					{translate('learnMore')}
				</Link>
			)}
		</Block>
	);
};

export default ArgumentCard;
