import React from 'react';
import type {Hit} from '../../../types/Algolia';
import {LastProducts} from './LastProducts';

type Props = {
	products: Hit[];
	catalogList: string;
	titleLabel: string;
	linkLabel: string;
};

const ByCategory = ({products, catalogList, titleLabel, linkLabel}: Props) => (
	<LastProducts
		lastProducts={products}
		title={
			<h2 className="title-2 text-lg text-center mt-10">{titleLabel}</h2>
		}
		btnText={linkLabel}
		as={catalogList}
	/>
);

export default ByCategory;
