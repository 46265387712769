import React, {useContext, useState} from 'react';
import {useInterval} from 'react-use';
import {AnimatePresence, motion} from 'framer-motion';
import {I18nContext} from '../../../i18n';

type VariantsType = {
	initial: Record<string, any>;
	animate: Record<string, any>;
	exit: Record<string, any>;
};
type WordProps = {
	currentItem: string;
	className?: string;
	variants?: VariantsType;
};
const VARIANTS = {
	initial: {
		opacity: 0,
		y: 20,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.1,
		},
	},
	exit: {
		y: -20,
		opacity: 0,
		transition: {
			duration: 0.1,
		},
	},
};
const INTERVAL_WORDS = 3000;

const Word = ({currentItem, className, variants = VARIANTS}: WordProps) => {
	const {translate} = useContext(I18nContext);
	return (
		<AnimatePresence mode="wait">
			<motion.span
				className={className}
				key={currentItem}
				initial="initial"
				animate="animate"
				exit="exit"
				variants={variants}
			>
				{translate(currentItem)}
			</motion.span>
		</AnimatePresence>
	);
};

type AnimatedWordProps = {
	words: string[];
	className?: string;
	variants?: VariantsType;
	interval?: number;
};

const AnimatedWord = ({
	className = 'block absolute left-0 right-0 top-0',
	words,
	variants,
	interval = INTERVAL_WORDS,
}: AnimatedWordProps) => {
	const [currentKeyIndex, setCurrentKeyIndex] = useState(0);
	useInterval(() => {
		setCurrentKeyIndex(index =>
			words.length === index + 1 ? 0 : index + 1,
		);
	}, interval);
	return (
		<Word
			currentItem={words[currentKeyIndex]}
			className={className}
			variants={variants}
		/>
	);
};

export default AnimatedWord;
