import React, {useState} from 'react';
import {useMount} from 'react-use';
import {getLastProducts} from '../../../utils/algolia-repository';
import {LastProducts} from './LastProducts';

type Props = {
	title: React.ReactNode;
	btnText: string;
	as?: string;
	className?: string;
};

const StandAloneLastProduct = ({title, btnText, className, as}: Props) => {
	const [lastProducts, setLastProducts] = useState([]);
	useMount(() =>
		getLastProducts(4).then(products => setLastProducts(products)),
	);
	return (
		lastProducts.length && (
			<LastProducts
				lastProducts={lastProducts}
				title={title}
				btnText={btnText}
				as={as}
				className={className}
			/>
		)
	);
};

export default StandAloneLastProduct;
