import React, {useContext} from 'react';
import {searchParams} from '@capcar/utils-product';
import {I18nContext} from '../../../i18n';
import type {Hit} from '../../../types/Algolia';
import {LastProducts} from './LastProducts';

type Props = {
	brands: string[];
	versions?: string[];
	products: Hit[];
};

const ByBrandAndVersion = ({brands, versions, products}: Props) => {
	const {translate} = useContext(I18nContext);
	const catalogList =
		brands.length > 1 && (!versions || versions.length > 1)
			? '/voiture-occasion'
			: `/voiture-occasion?${searchParams({
					brands,
					versions,
				})}`;
	const title = (
		<h2 className="title-2 text-lg text-center mt-10">
			{brands.length === 1
				? translate('lastProductModelToSale', {
						brand: brands[0],
						version:
							versions && versions.length === 1
								? versions[0]
								: '',
					})
				: translate('lastProductToSale')}
		</h2>
	);
	const btnTxt =
		brands.length === 1
			? translate('seeAllProductModelToSale', {
					brand: brands[0],
					version:
						versions && versions.length === 1 ? versions[0] : '',
				})
			: translate('seeAllProductToSale');
	return (
		<LastProducts
			lastProducts={products}
			title={title}
			btnText={btnTxt}
			as={catalogList}
		/>
	);
};

export default ByBrandAndVersion;
