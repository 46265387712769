import React, {useContext} from 'react';
import Head from 'next/head';
import config from '../../../../config';
import {I18nContext} from '../../../../i18n';
import AnimatedWord from '../../../rich/AnimatedWord';
import Search from './Search';

const {CDN_HOST} = config;
const WORDS = [
	'inConfidence',
	'warrantySixMonth',
	'atBestPrice',
	'controlledByOurTechnicians',
	'shippedHome',
];

const Hero = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="p-1 bg-lightBlue-375 lg:bg-hero-gradient-desktop mb-1">
			<div className="bg-clouds bg-repeat-x">
				<Head>
					<link
						rel="preload"
						href={`${CDN_HOST}/static/theme-capcar/hero-illustration/hero-illustration@2x.webp`}
						as="image"
						type="image/webp"
						media="(min-width: 1100px)"
					/>
					<link
						rel="preload"
						href={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car@2x.webp`}
						as="image"
						type="image/webp"
						media="(min-width: 600px) and (max-width: 1099px)"
					/>
					<link
						rel="preload"
						href={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car.webp`}
						as="image"
						type="image/webp"
						media="(max-width: 599px)"
					/>
					<link
						rel="preload"
						as="image"
						href={`${CDN_HOST}/static/theme-capcar/clouds.svg`}
						type="image/svg+xml"
					/>
				</Head>
				<div className="max-w-6xl m-auto pt-18">
					<div className="flex flex-col lg:flex-row px-4 max-w-sm lg:max-w-full m-auto">
						<div className="text-3xl lg:text-4_75xl text-center lg:text-left pt-6 lg:pt-0 pb-2 lg:pb-10 lg:mt-8 leading-10 lg:leading-12">
							<h1>{translate('buyACarWithConfidence.1')}</h1>
							<div className="font-bold relative pb-12 block">
								<AnimatedWord words={WORDS} />
							</div>
						</div>
						<picture>
							<source
								srcSet={`${CDN_HOST}/static/theme-capcar/hero-illustration/hero-illustration@2x.webp`}
								type="image/webp"
							/>
							<img
								src={`${CDN_HOST}/static/theme-capcar/hero-illustration/hero-illustration@2x.png`}
								alt="Achat d'une voiture"
								className="w-0 hidden lg:block lg:w-144 transform translate-y-1_5"
								width="563"
								height="247"
								loading="lazy"
							/>
						</picture>
					</div>
					<Search />
					<div className="lg:hidden px-10 flex justify-center items-baseline w-full transform translate-y-3_4 mobile:translate-y-4_5">
						<picture>
							<source
								srcSet={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car@2x.webp`}
								type="image/webp"
								media="(min-width: 600px)"
							/>
							<source
								srcSet={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car@2x.png`}
								media="(min-width: 600px)"
							/>
							<source
								srcSet={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car.webp`}
								type="image/webp"
							/>
							<img
								src={`${CDN_HOST}/static/theme-capcar/hero-car/hero-car.png`}
								alt="Achat d'une voiture"
								className="lg:hidden lg:h-0"
								width="600"
								height="231"
								loading="lazy"
							/>
						</picture>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
