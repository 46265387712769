import React, {useContext} from 'react';
import cx from 'classnames';
import range from 'lodash/range';
import {useRouter} from 'next/compat/router';
import queryString from 'query-string';
import {useForm} from 'react-hook-form';
import config from '../../../config';
import {AnalyticsContext} from '../../../contexts/analytics';
import {I18nContext} from '../../../i18n';
import ArrowIcon from '../../../public/static/theme-capcar/arrow.svg';
import SearchIcon from '../../../public/static/theme-capcar/search-regular.svg';

const defaultValues = {
	q: '',
	'price-max': '',
	'mileage-max': '',
};
const mileageOptions = [10000, ...range(50000, 250000, 50000)].map(mileage => ({
	label: mileage.toLocaleString(),
	value: mileage.toString(10),
}));
const priceOptions = [
	...range(5000, 10000, 1000),
	...range(10000, 20000, 2500),
	...range(20000, 30000, 5000),
	...range(30000, 60000, 10000),
].map(price => ({
	label: price.toLocaleString(),
	value: price.toString(10),
}));
const {SITE_DOMAIN_URL} = config;

const ProductSearchForm = () => {
	const router = useRouter();
	const {ga} = useContext(AnalyticsContext);
	const {translate} = useContext(I18nContext);
	const form = useForm({
		mode: 'onChange',
		defaultValues,
	});
	const {register, handleSubmit, formState} = form;
	const {dirtyFields} = formState;
	const onSubmit = handleSubmit(values => {
		ga('send', 'event', 'buyer_form', 'submit');
		router.push(
			`/voiture-occasion?${queryString.stringify(values, {
				skipEmptyString: true,
			})}`,
		);
	});
	return (
		<form
			onSubmit={onSubmit}
			className="flex flex-col lg:flex-row"
			itemProp="potentialAction"
			itemScope
			itemType="https://schema.org/SearchAction"
			action="/voiture-occasion"
			method="get"
		>
			<meta
				itemProp="target"
				content={`${SITE_DOMAIN_URL}/voiture-occasion?q={q}`}
			/>
			<meta itemProp="query-input" content="required name=q" />
			<label
				htmlFor="carInput"
				className="flex flex-1 flex-col leading-none bg-gray-8 px-5 rounded-lg mb-4 lg:mb-0 justify-center py-5 border border-darkBlue-950 border-opacity-0 focus-within:border-opacity-100 transition"
			>
				<p className="font-bold uppercase text-xs">
					{translate('car')}
				</p>
				<input
					id="carInput"
					{...register('q')}
					placeholder={translate('searchACar')}
					className={cx(
						'bg-transparent w-full lg:w-auto text-base pt-1 focus:outline-none truncate',
						!dirtyFields.q && 'text-gray-50',
					)}
				/>
			</label>
			<label
				htmlFor="inputMileage"
				className="flex flex-1 flex-col leading-none lg:max-w-1/4 bg-gray-8 px-5 rounded-lg lg:mx-2 mb-4 lg:mb-0 justify-center py-5 border border-darkBlue-950 border-opacity-0 focus-within:border-opacity-100 transition"
			>
				<p className="font-bold uppercase text-xs">
					{translate('KMmax')}
				</p>
				<div className="relative pt-1 h-6">
					<select
						id="inputMileage"
						{...register('mileage-max')}
						className={cx(
							'absolute -top-8 left-0 pt-9 pb-5 bg-transparent text-base appearance-none focus:outline-none w-full leading-tight',
							!dirtyFields['mileage-max'] && 'text-gray-50',
						)}
					>
						<option hidden disabled value="">
							{translate('KMmaxPlaceholder')}
						</option>
						{mileageOptions.map(({value, label}) => (
							<option key={value} value={value} label={label}>
								{label}
							</option>
						))}
					</select>
					<ArrowIcon className="fill-current w-2 absolute right-0 top-0 bottom-0 m-auto pointer-events-none" />
				</div>
			</label>
			<label
				htmlFor="inputPrice"
				className="flex flex-1 flex-col leading-none lg:max-w-1/5 bg-gray-8 px-5 rounded-lg mb-4 lg:mb-0 justify-center py-5 relative border border-darkBlue-950 border-opacity-0 focus-within:border-opacity-100 transition"
			>
				<p className="font-bold uppercase text-xs">
					{translate('PriceMax')}
				</p>
				<div className="relative pt-1 h-6">
					<select
						id="inputPrice"
						{...register('price-max')}
						className={cx(
							'absolute -top-8 left-0 pt-9 pb-5 bg-transparent text-base appearance-none focus:outline-none w-full leading-tight',
							!dirtyFields['price-max'] && 'text-gray-50',
						)}
					>
						<option hidden disabled value="">
							{translate('PriceMaxPlaceholder')}
						</option>
						{priceOptions.map(({value, label}) => (
							<option key={value} value={value} label={label}>
								{label}
							</option>
						))}
					</select>
					<ArrowIcon className="fill-current w-2 absolute right-0 top-0 bottom-0 m-auto pointer-events-none" />
				</div>
			</label>
			<button
				type="submit"
				aria-label={translate('search')}
				className="font-white lg:w-19 h-19 bg-green-550 hover:bg-green-750 rounded lg:rounded-full flex items-center lg:ml-2 justify-center focus:outline-none border border-white transition focus:border-darkBlue-950"
			>
				<SearchIcon
					className="text-white inline mx-2 lg:mx-5 w-5 lg:w-auto"
					width={30}
					height={30}
				/>
				<span className="text-white lg:hidden">
					{translate('search')}
				</span>
			</button>
		</form>
	);
};

export default ProductSearchForm;
