import React, {useContext, useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import config from '../../../../config';
import {I18nContext} from '../../../../i18n';
import SearchIcon from '../../../../public/static/theme-capcar/search-regular.svg';
import SellIcon from '../../../../public/static/theme-capcar/sell_find.svg';
import ProductSearchForm from '../../../rich/ProductSearchForm';
import Link, {LinkType} from '../../../system/Link';

const {SITE_DOMAIN_URL} = config;

const Search = () => {
	const {translate} = useContext(I18nContext);
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	return (
		<>
			<div className="hidden sm:block h-128 lg:h-26 pt-7">
				{isClient && (
					<motion.div
						initial="hidden"
						animate="visible"
						variants={variants}
					>
						<div
							className="bg-white rounded-xl max-w-6xl m-auto px-4 md:px-7 py-5 shadow-4xl relative mx-6 lg:mx-auto"
							itemScope
							itemType="https://schema.org/WebSite"
						>
							<meta itemProp="url" content={SITE_DOMAIN_URL} />
							<p className="font-bold pb-2 text-center lg:text-left text-lg lg:text-base mb-2_5">
								{translate('searchACar.2')}
							</p>
							<ProductSearchForm />
						</div>
					</motion.div>
				)}
			</div>
			<div className="mt-6 mb-6 text-center sm:hidden">
				<Link type={LinkType.IN_APP} href="/voiture-occasion">
					<a className="inline-flex items-center m-auto py-2_5 px-5 bg-white rounded-full shadow-btn">
						<SearchIcon
							width={20}
							height={20}
							className="text-green-750 inline mr-5"
						/>
						<span className="font-bold">
							{translate('findACar.buy')}
						</span>
					</a>
				</Link>
			</div>
			<div className="mb-15 mt-6 text-center sm:hidden">
				<Link type={LinkType.IN_APP} href="/vendre-sa-voiture">
					<a className="inline-flex items-center m-auto py-2_5 px-5 bg-white rounded-full shadow-btn">
						<SellIcon
							width={20}
							height={20}
							className="text-green-750 inline mr-5"
						/>
						<span className="font-bold">
							{translate('sellOnesCar')}
						</span>
					</a>
				</Link>
			</div>
		</>
	);
};

const variants = {
	hidden: {opacity: 0},
	visible: {opacity: 1, transition: {duration: 1}},
};

export default Search;
